import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'TemplateMoreDealCollapse',
  props: {
    moreItems: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
    }),
    items() {
      const { moreItems } = this;
      if (!moreItems || moreItems.length === 0) return [];
      return [...moreItems.sort((a, b) => new Date(a.checkinDate) - new Date(b.checkinDate))].slice(0, 4);
    },
  },
  mounted() {
    document.querySelector('.st-content').addEventListener('scroll', () => {
      if (this.$refs.dropdown) this.$refs.dropdown.hide();
    });
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('.');
    },
    calcNight(day1, day2) {
      const a = dayjs(this.changeDateFormat(day1), 'DD/MM/YY');
      const b = dayjs(this.changeDateFormat(day2), 'DD/MM/YY');
      return a.diff(b, 'day');
    },
  },
};
