<template>
  <div>
    <b-dropdown variant="success" size="sm" ref="dropdown" no-caret>
      <template #button-content>
        <i class="fas fa-arrow-circle-down"></i>{{ "\xa0" }}{{$t('home.more-date-click')}}
      </template>
      <b-dropdown-item v-for="(item, inx) in items" :key="inx" :href="item.productPageUrl">
        <div class="display-flex">
          <div class="date-range" onclick="return false;">
            <span dir="ltr">{{ changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</span>
          </div>
          <div class="night-box" onclick="return false;">
            <span> {{ calcNight(item.checkoutDate, item.checkinDate) }} {{ (calcNight(item.checkoutDate, item.checkinDate) > 1) ? $t("home.nights") : $t("home.night") }} </span>
          </div>
          <div class="price-box" onclick="return false;">
            <span class="currency-symbol"> ₪</span><strong>{{Math.round(item.totalAfterDiscount)}}</strong>
          </div>
          <div class="pl-0 pr-2 py-0">
            <b-button :href="item.productPageUrl" size="sm" variant="primary">{{ $t("home.slide-deal.order-now") }}</b-button>
          </div>
        </div>
      </b-dropdown-item>

    </b-dropdown>
  </div>
</template>
<script>
import {
  VBToggle,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import TemplateMoreDealCollapse from './TemplateMoreDealCollapse';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'MoreDealCollapseTheme1',
  mixins: [TemplateMoreDealCollapse],
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>
<style>
  .collapse-button .dropdown-toggle{
    padding: 1px 4px;
  }
  .collapse-button .dropdown-menu{
    box-shadow: grey 1px 1px 10px;
  }
  .collapse-button .dropdown-item {
    cursor: default;
  }
  .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: white;
  }
  @media (max-width: 768px) {
    .collapse-button .dropdown-item {
      padding: 0.25rem 1rem;
    }
  }
</style>
<style scoped>
  .display-flex{
    display: flex;
    text-align: right;
  }
  .date-range, .price-box {
    background-color: #313232;
    color: white;
  }
  .date-range {
    width: 180px;
    text-align-last: justify;
  }
  .price-box {
    font-size: 24px;
    /* width: 75px; */
  }
  .dropdown-item .date-range, .dropdown-item .price-box, .dropdown-item .night-box {
    padding: 0.25rem 0.8rem;
  }
  @media (max-width: 768px) {
    .dropdown-item .date-range, .dropdown-item .price-box, .dropdown-item .night-box{
      font-size: 13px;
    }
    .date-range {
      width: 142px;
    }
    .price-box {
      font-size: 24px;
      width: 40px;
    }
    .dropdown-item .date-range, .dropdown-item .price-box, .dropdown-item .night-box {
      padding: 0.25rem 0.1rem;
      text-align: center;
    }
  }
</style>
